<!-- 履约保函 -->
<template>
  <div class="agreement">
    <div style="display: flex;justify-content: space-between;">
      <div style="width: 78%;background: #fff;">
        <div
          style="height: 40px;background: #039EEE;font-size: 16px;font-family: SimHei;font-weight: bold;color: #FFFFFF;line-height: 40px;"
        >
          <img
            src="../assets/订单 (1)@2x.png"
            alt
            style="width: 17px;height: 17px;vertical-align: text-bottom;margin-left: 13px;"
          />
          申请流程
        </div>
        <div class="cardTop-middle">
          <div style="width: 17%; height: 114px;">
            <img src="../assets/1@2x.png" alt style="height: 100%; width: 100%;" />
          </div>
          <div style="width: 17%; height: 114px;">
            <img src="../assets/2@2x.png" alt style="height: 100%; width: 100%;" />
          </div>
          <div style="width: 17%; height: 114px;">
            <img src="../assets/3@2x.png" alt style="height: 100%; width: 100%;" />
          </div>
          <div style="width: 17%; height: 114px;">
            <img src="../assets/4@2x.png" alt style="height: 100%; width: 100%;" />
          </div>
          <div style="width: 17%; height: 114px;">
            <img src="../assets/5@2x.png" alt style="height: 100%; width: 100%;" />
          </div>
        </div>
      </div>
      <div style="width: 20%;">
        <div
          style="height: 40px;background: #039EEE;font-size: 16px;font-family: SimHei;font-weight: bold;color: #FFFFFF;line-height: 40px;"
        >
          <img
            src="../assets/帮助中心 (1)@2x.png"
            alt
            style="width: 17px;height: 17px;vertical-align: text-bottom;margin-left: 13px;"
          />
          帮助中心
        </div>
        <div style="background-color: #fff;height: 153px;">
          <div style="margin-left: 30px">
            <img
              src="../assets/wire(1).png"
              alt
              style="width: 20px;height: 20px;vertical-align: text-bottom;"
            />
            <el-button
              type="text"
              style="
                color: rgb(51, 126, 190);
                font-size: 16px;
                margin-left: 5px;
                letter-spacing: 3px;
              "
              @click="operationManual"
            >操作手册</el-button>
          </div>
          <div style="margin-left: 30px">
            <img
              src="../assets/电 话 (1)@2x.png"
              style="width: 20px;height: 20px;vertical-align: text-bottom;"
              alt
            />
            <el-button
              type="text"
              style="
                color: rgb(51, 126, 190);
                font-size: 16px;
                margin-left: 5px;
                letter-spacing: 3px;
                cursor: default;
              "
            >客服电话：400-183-1833</el-button>
          </div>
        </div>
      </div>
    </div>
    <div
      style="height: 40px;background: #039EEE;font-size: 16px;font-family: SimHei;font-weight: bold;color: #FFFFFF;line-height: 40px;margin-top: 15px;"
    >
      <img
        src="../assets/订单 (1)@2x (1).png"
        style="width: 17px;height: 17px;vertical-align: text-bottom;margin-left: 13px;"
        alt
      />
      履约保函申请
    </div>
    <div style="background-color: #fff;padding: 20px;">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        style="width: 30%;margin: 0 auto;"
      >
        <el-form-item label="保函类型" prop="type">
          <el-select v-model="ruleForm.type" placeholder="请选择保函类型">
            <el-option label="公司保函" value="2"></el-option>
            <el-option label="银行保函" value="1"></el-option>
            <el-option label="保险保函" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="中标通知书">
          <span class="befor_1">*</span>
          <el-upload
            class="upload-demo"
            action="api/newGuarantee.do?callBidFileUpLoad"
            name="bidAdviceNoteFile"
            :on-success="bidAdviceNoteFile"
            accept=".pdf"
            :limit="1"
          >
            <el-button size="small">上传文件</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="招标文件">
          <span class="befor_2">*</span>
          <el-upload
            class="upload-demo"
            action="api/newGuarantee.do?callBidFileUpLoad"
            name="callBidsFile"
            :on-success="callBidsFile"
            accept=".pdf"
            :limit="1"
          >
            <el-button size="small">上传文件</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="联系人" prop="linkman">
          <el-input v-model="ruleForm.linkman"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input v-model="ruleForm.phone"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
//这里导入其他文件（组件，工具js，第三方插件js，json文件，图片文件等等）
//import 《组件名称》 from '《组件路径》';
import downloadjs from "downloadjs";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},

  // 组件名称
  name: "",

  // 这里存放数据
  data() {
    return {
      ruleForm: {
        type: "",
        linkman: "",
        phone: "",
      },
      rules: {
        type: [{ required: true, message: "请选择保函类型", trigger: "blur" }],
        linkman: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入联系人电话", trigger: "blur" },
        ],
      },
      bidAdviceNoteFilePath: "",
      callBidFilePath: "",
      firmName: "",
      CAKeyEncStr: "",
    };
  },

  // 监听属性 类似于data概念
  computed: {},

  // 监控data中的数据变化
  watch: {},

  // 方法集合
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 成功
          this.axios({
            method: "GET",
            url: "/newGuarantee.do?belowLineGuaranteeBusinessApply",
            params: {
              guaType: this.ruleForm.type,
              businessType: 1,
              linkPerson: this.ruleForm.linkman,
              phone: this.ruleForm.phone,
              from: 10,
              callBidFilePath: this.callBidFilePath,
              bidAdviceNoteFilePath: this.bidAdviceNoteFilePath,
            },
          }).then((res) => {
            if (res.data.status == 0) {
              this.$message({
                message: "申请成功",
                type: "success",
              });
              this.$router.push("/main");
            } else {
              this.$message({
                message: res.data.statusInfo,
                type: "error",
              });
            }
          });
        } else {
          // 失败
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    // 中标通知书上传成功回调
    bidAdviceNoteFile(response) {
      if (response.success == true) {
        this.$message({
          message: "上传文件成功!",
          type: "success",
        });
        this.bidAdviceNoteFilePath = response.attributes.filePath;
      } else {
        this.$message({
          message: "上传文件失败，" + response.msg,
          type: "error",
        });
      }
    },

    // 招标文件上传成功回调
    callBidsFile(response) {
      if (response.success == true) {
        this.$message({
          message: "上传文件成功!",
          type: "success",
        });
        this.callBidFilePath = response.attributes.filePath;
      } else {
        this.$message({
          message: "上传文件失败，" + response.msg,
          type: "error",
        });
      }
    },

    // 操作手册
    operationManual() {
      window.open("https://ejlb673kl8.feishu.cn/wiki/wikcnjVAIfeV1fZkcPvyY0lhHGd")
      // var operationManualPdf = "https://www.suzhou.haneton.com/manualsSZ.pdf";
      // downloadjs(operationManualPdf);
    },

    getEnterpriseMessage() {
      var type = window.sessionStorage.getItem("type");
      if (type == "CA") {
        var params = {
          CAKeyId: this.firmName,
          from: 10,
          CAKeyEncStr: this.CAKeyEncStr,
        };
      } else if (type == "CFCA") {
        var params = {
          CAKeyId: this.firmName,
          from: 10,
          CAKeyEncStr: window.sessionStorage.getItem("content"),
        };
      } else {
        var params = {
          handsetNum: window.sessionStorage.getItem("phone"),
          from: 10,
        };
      }
      this.axios({
        method: "GET",
        url: "/newGuarantee.do?existEnterpriseInfo",
        params: params,
      }).then((res) => {
        if (res.data.data.userStatus == '1' || (res.data.data.userStatus == '3' && !res.data.data.apply)) {
          this.$confirm("企业信息未完善,请先完善企业信息", "提示", {
            confirmButtonText: "确定",
            closeOnPressEscape: false,
            closeOnClickModal: false,
            closeOnHashChange: false,
            showCancelButton: false,
            showClose: false,
            type: "warning",
          }).then(() => {
            if (type == "account") {
              this.$router.push("/enterprise");
            } else {
              this.$router.push("/message");
            }
          });
        } else if(res.data.data.userStatus == '3' && res.data.data.apply){
          this.$confirm(res.data.data.errorInfo+"，请先完善企业信息", "提示", {
            confirmButtonText: "确定",
            closeOnPressEscape: false,
            closeOnClickModal: false,
            closeOnHashChange: false,
            showCancelButton: false,
            showClose: false,
            type: "warning",
          }).then(() => {
            if (type == "account") {
              this.$router.push("/enterprise");
            } else {
              this.$router.push("/message");
            }
          });
        }
      });
    },
  },

  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},

  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.firmName = window.sessionStorage.getItem("firmName");
    this.CAKeyEncStr = window.sessionStorage.getItem("date");
    this.getEnterpriseMessage();
  },

  // 生命周期 - 创建之前
  beforeCreate() {},

  // 生命周期 - 挂载之前
  beforeMount() {},

  // 生命周期 - 更新之前
  beforeUpdate() {},

  // 生命周期 - 更新之后
  updated() {},

  // 生命周期 - 销毁之前
  beforeDestroy() {},

  // 生命周期 - 销毁完成
  destroyed() {},

  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},

  // keep-alive缓存离开之后触发
  deactivated() {},
};
</script>

<style scoped>
.agreement {
  height: 100%;
}
.cardTop-middle {
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  height: 153px;
}
.content {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  border: 1px solid rgb(51, 126, 190);
  text-align: center;
  color: rgb(51, 126, 190);
}
.explain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}
.part {
  text-align: center;
  font-size: 12px;
  color: rgb(51, 126, 190);
}
.befor_1 {
  position: absolute;
  left: -91px;
  color: #f56c6c;
}
.befor_2 {
  position: absolute;
  left: -78px;
  color: #f56c6c;
}
</style>